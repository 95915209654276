import React from 'react'
import Layout from '@global/layout'
import { StaticImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const IndexPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				desktop: file(relativePath: { eq: "Idea.png" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 2560) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)

	const imageData = data.desktop.childImageSharp.fluid

	return (
		<Layout>
			<BackgroundImage Tag='section' fluid={imageData} className='bg-image'>
				<section className='section'>
					<div className='wrapper wrapper--sm-on-md flex flex--collapse-md flex--center-vt'>
						<div className='section-top__title'>
							{/* <p className='sub-title'>Defiant</p> */}
							<h1 className='title'>Defiant Pixels Society</h1>
						</div>

						<div className='section-top__img'>
							<div className='info'>
								<a
									className='profile-container'
									href='https://twitter.com/Perejil3D'
									target='_blank'
									rel='noreferrer'
								>
									<StaticImage
										src='../images/Artist_profile_Picture.png'
										alt='DPS Logo'
									/>
									<p className=''>@parsley_3D</p>
								</a>
							</div>
							<div className='hero-image-animation'>
								<StaticImage src='../images/Art_displayed.png' alt='DPS Logo' />
							</div>
						</div>
					</div>
				</section>
			</BackgroundImage>
		</Layout>
	)
}

export default IndexPage
